module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gazonul","short_name":"Gazonul","description":"Website construit pentru a prezenta toate produsele, serviciile si istoria firmei Gazonul","start_url":"/","background_color":"#870b0b","theme_color":"#870b0b","display":"standalone","icon":"static/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-49456350-1","anonymize":false},"facebookPixel":{"pixelId":"3148790815134570"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QGQRLD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49456350-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/gdpr.html","/termeni-si-conditii.html","/politica-de-utilizare-cookie.html","/cere-oferta.html","/pagina-in-constructie.html","/test.html"],"pageTransitionDelay":0,"optimizeId":"GTM-M479NX8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"3148790815134570"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
